<template>
  <!-- Nilai -->
  <b-card v-show="completed">
    <b-card-header header-class="px-0 pt-0">
      <h4 class="mb-0">
        Penilaian
      </h4>
    </b-card-header>

    <b-row>
      <b-col
        class="mb-2"
        md="3"
        lg="2"
      >
        <p>Nilai akreditasi</p>
        <template v-if="nilai">
          <h1>{{ nilai }}</h1>
          <p class="font-weight-bold">{{ text }}</p>
        </template>
        <h5 v-else>Belum dinilai</h5>
      </b-col>
      <b-col
        md="9"
        lg="10"
      >
        <p>Dokumen pendukung</p>
        <template v-if="files.length">
          <b-row>
            <b-col
              v-for="file in files"
              :key="file.id"
              sm="6"
              md="12"
              lg="4"
              class="mb-1"
            >
              <b-card
                border-variant="secondary"
                class="h-100"
              >
                <div class="d-flex flex-column justify-content-between h-100">
                  <h6 class="text-truncate">
                    {{ file.original_name }}
                  </h6>
                  <b-button
                    variant="outline-primary text-primary"
                    :href="`${BACKEND_URL}/${file.unique_name}`"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {{ viewableFileTypes.some(c => file.type.includes(c)) ? 'Lihat' : 'Unduh' }}
                  </b-button>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </template>
        <h5 v-else>
          Tidak ada berkas
        </h5>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
} from 'bootstrap-vue'

import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
  },
  props: {
    gradable: [String, Number],
    ts: String,
    completed: Boolean,
    programStudi: {
      type: String,
      required: false,
      default: ''
    },
    shortName: {
      type: String,
      required: false,
      default: ''
    },
  },
  data() {
    return {
      nilai: 0,
      text: '',
      files: [],
      viewableFileTypes: ['image', 'pdf', 'presentasion'],
      fetchGradeLoading: false,

      BACKEND_URL: process.env.VUE_APP_BACKEND_URL
    }
  },
  created() {
    this.fetchGrade()
  },
  methods: {
    fetchGrade() {
      this.fetchGradeLoading = true
      useJwt.http.get(`grade`, {
        params: {
          type: this.gradable,
          ts: this.ts.replace(/ /g, ''),
          program_studi: this.programStudi,
          short_name: this.shortName
        }
      })
        .then(response => {
          if (response.data.grade) {
            this.nilai = response.data.grade.grade
            this.files = response.data.grade.grade_files
            this.text = response.data.grade.text
          }
        })
        .catch(() => this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' }))
        .finally(() => this.fetchGradeLoading = false)
    }
  }
}
</script>