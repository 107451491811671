<template>
  <v-tour :name="name" :steps="tourSteps" :options="options"></v-tour>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

export default {
  props: {
    steps: Array
  },
  computed: {
    tourSteps() {
      return this.steps.map(step => {
        step.offset = -500

        return step
      })
    }
  },
  data() {
    return {
      name: uuidv4(),
      options: {
        labels: {
          buttonSkip: 'Batal',
          buttonPrevious: 'Kembali',
          buttonNext: 'Lanjut',
          buttonStop: 'Selesai'
        }
      }
    }
  },
  methods: {
    showTour() {
      this.$tours[this.name].start()
    }
  }
}
</script>

<style lang="scss">
  .v-step {
    background-color: white !important;
    color: black !important;

    .v-step__header {
      background-color: #009b4c !important;
      color: white !important;
    }

    .v-step__button {
      border-color: #009b4c !important;
      color: #009b4c !important;
    }
  }
</style>