<template>
  <div>
    <!-- Header -->
    <b-row
      align-h="between"
      align-v="center"
      class="py-2"
    >
      <b-col
        class="mb-2 mb-md-0"
        cols="12"
      >
        <!-- Page title -->
        <h2
          id="title"
          class="mb-0 mr-1 mb-md-2 d-inline-block"
        >
          Data Kuantitatif-LAMDIK
        </h2>
      </b-col>
      <b-col cols="12">
        <b-row
          align-v="center"
          align-h="between"
        >
          <b-col
            sm="6"
            class="mb-2 mb-sm-0"
          >
            <b-row class="ml-0">
              <h5
                class="mb-0 mr-1 d-inline-block"
                v-text="`Tahun Semester   :`"
              />
              <h5
                id="tahun-semester_ts"
                :class="`mb-0 ml-0 ${ts === undefined ? 'text-danger' : ''}`"
                v-text="
                  `${
                    ts !== undefined
                      ? ts
                      : '(anda belum mengatur Profil, silahkan refresh halaman jika telah mengisi)'
                  }`
                "
              />
            </b-row>
          </b-col>
          <b-col
            sm="6"
            class="d-flex justify-content-between justify-content-sm-end align-items-center"
          >
            <b-button
              id="export"
              variant="warning"
              class="action-button mr-1"
              size="sm"
              @click="exportAllData()"
            >
              <b-spinner
                v-if="spinnerDownload"
                small
                type="grow"
              />
              <feather-icon
                v-if="!spinnerDownload"
                icon="DatabaseIcon"
              />
              <span
                v-if="spinnerDownload"
                class="ml-1"
              > Unduh Semua . . . </span>
              <span
                v-else
                class="ml-1"
              > Unduh Semua </span>
            </b-button>
            <b-button
              variant="info"
              size="sm"
              @click="showTour"
            >
              <feather-icon icon="HelpCircleIcon" />
              <span class="ml-1">Panduan</span>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Table -->
    <b-card body-class="p-0">
      <!-- Header -->
      <b-card-header>
        <b-col md="6">
          <h4 class="mb-1 mb-md-0">
            Daftar Tabel
          </h4>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col md="6">
              <v-select
                id="status"
                v-model="status"
                :options="statusesOptions"
                placeholder="Pilih status"
              >
                <template v-slot:option="option">
                  <span>{{ option.label }}</span>
                </template>
              </v-select>
            </b-col>
            <b-col md="6">
              <b-form-input
                v-model="searchKeyword"
                type="text"
                placeholder="Cari Tabel"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-card-header>

      <!-- Body -->
      <overlay-loading :loading="fetchLoading">
        <b-table
          id="table-transition-gjmf"
          :items="reports"
          :fields="fields"
          class="m-0"
          responsive="sm"
          hover
          show-empty
          caption-top
          primary-key="number"
          :tbody-transition-props="transProps"
        >
          <!-- No Data -->
          <template #empty>
            <p class="mb-0 py-2 text-center">
              Tidak ada data
            </p>
          </template>

          <!-- <template
            v-if="multipleHeader"
            #thead-top="data"
          >
            <b-tr>
              <b-th :colspan="multipleHeader.startFrom"/>
              <gjmf-header
                ref="removeJustThis"
                v-for="(header, index) in multipleHeader.content"
                :key="index"
              >
                <b-th variant="primary" :class="header.align" :colspan="header.span" v-text="header.value"/>
              </gjmf-header>
              <b-th :colspan="multipleHeader.endFrom"/>
            </b-tr>
          </template> -->
          <template #cell(action)="data">
            <div class="d-flex align-items-center">
              <b-button
                variant="primary"
                class="action-button mr-1"
                :to="`/op/lkps/laporan/${data.item.list_lkps_table.name_sheet}/${data.item.id}`"
                size="sm"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-if="data.item.link_file_excel"
                :pressed="true"
                variant="warning"
                class="action-button mr-1"
                :href="`${BACKEND_URL}/${data.item.link_file_excel}`"
                size="sm"
              >
                <feather-icon icon="DownloadIcon" />
              </b-button>
            </div>
          </template>
          <template #cell(status)="data">
            <b-badge :variant="statusVariantBadge(data.item.status || 'Tidak ada status')">
              <strong>{{ data.item.status ||'Tidak ada status' }}</strong>
            </b-badge>
          </template>
        </b-table>
      </overlay-loading>

      <!-- Footer -->
      <b-card-footer>
        <b-row
          align-h="between"
          align-v="center"
        >
          <b-col
            sm="2"
            class="mb-2 mb-sm-0"
          >
            <!-- Select amount of data per page -->
            <b-form-select
              v-model="pagination.selectedAmount"
              :options="pagination.amountOptions"
            />
          </b-col>
          <b-col
            col
            class="d-flex justify-content-center justify-content-sm-end"
          >
            <!-- Pagination -->
            <b-pagination
              ref="pagination"
              v-model="pagination.currentPage"
              :total-rows="pagination.totalItems"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              :per-page="pagination.selectedAmount"
            />
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>

    <!-- Grade -->
    <Grade
      v-show="reports.length && completed"
      gradable="DK"
      :ts="ts"
      :program-studi="programStudi"
      :short-name="shortName"
    />

    <v-tour
      ref="tour"
      :steps="tourSteps"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardFooter,
  BTable,
  BPagination,
  BFormSelect,
  BButton,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import VTour from '@/views/components/Tour.vue'

import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import OverlayLoading from '@/views/components/OverlayLoading.vue'

import Grade from '../../components/operator/Grade.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardFooter,
    BTable,
    BPagination,
    BFormSelect,
    BButton,
    BFormInput,
    OverlayLoading,
    VTour,
    VSelect,
    BSpinner,
    Grade,
  },
  data() {
    return {
      transProps: {
        name: 'flip-list',
      },
      spinnerDownload: false,
      // Table fields, data, pagination
      fields: [
        { key: 'number', label: '#', sortable: true },
        // { key: 'id', label: '#' },
        { key: 'number_and_title', label: 'Nomor dan Judul Tabel', sortable: true },
        {
          key: 'status',
          label: 'Status',
          class: 'status',
          thStyle: { width: '300px' },
          sortable: true,
        },
        { key: 'action', label: 'Aksi', class: 'tombol-detail' },
      ],
      reports: [],
      pagination: {
        amountOptions: [5, 10, 25, 50, 100],
        selectedAmount: 10,
        totalItems: 0,
        currentPage: 1,
      },
      searchKeyword: '',
      timeoutDebounce: null,
      status: '',
      statusesOptions: [
        'Selesai',
        'Perlu Diperbaiki',
        'Dapat Ditinjau',
        'Dapat Ditinjau Ulang',
        'Proses Pengisian',
        'Tidak ada status',
      ],

      // loading
      fetchLoading: false,
      BACKEND_URL: process.env.VUE_APP_BACKEND_URL,

      // tour
      tourSteps: [
        {
          target: '#title',
          header: {
            title: 'Data Kuantitatif-LAMDIK',
          },
          params: {
            placement: 'right',
          },
          content:
              'Halaman ini berisi daftar-daftar tabel "<strong>Data Kuantitatif Laporan Evaluasi Diri LAM KEPENDIDIKAN</strong>".',
        },
        {
          target: '#tahun-semester_ts',
          params: {
            placement: 'right',
          },
          content:
              'Tahun semester yang ditampilkan, anda dapat mengubahnya di menu <strong>Profil</strong>.',
        },
        {
          target: '.tombol-detail',
          params: {
            placement: 'left',
          },
          content:
              'Tombol detail dengan icon mata untuk melihat dan mengedit isi data dari suatu tabel.',
        },
        {
          target: '.status',
          params: {
            placement: 'left',
          },
          content:
              'Status dari tabel yang menandakan proses dari tabel tersebut.',
        },
        {
          target: '#export',
          params: {
            placement: 'left',
          },
          content:
              'Tombol untuk <i>export</i> data menjadi <i>spreadsheet</i>.',
        },
      ],
    }
  },
  computed: {
    statusVariantBadge() { return any => this.$variantBadge[any] },
    statusVariantText() { return any => this.$variantText[any] },
    ts() {
      try {
        const { ts } = getUserData().lkps_profile
        return ts.replace('/', ' / ')
      } catch (err) {
        // re featch | whoIsMe
        console.log(`error ts ts() : ${err}`)
        return this.whoIsMe()
      }
    },
    programStudi() {
      try {
        const userData = getUserData()
        const department = userData.departments[0]
        const { program_studi } = department

        return program_studi
      } catch (err) {
        // re featch | whoIsMe
        console.log(`error ts programStudi() : ${err}`)
        return this.whoIsMe()
      }
    },
    shortName() {
      try {
        const userData = getUserData()
        const department = userData.departments[0]
        const { lkps_program } = department
        const { short_name } = lkps_program

        return short_name
      } catch (err) {
        // re featch | whoIsMe
        console.log(`error ts ${err}`)
        return this.whoIsMe()
      }
    },
  },
  watch: {
    'pagination.currentPage': function (page) {
      this.$store.commit('app/UPDATE_OPERATOR_LKPS_PAGE', page)
      this.fetchReports()
    },
    'pagination.selectedAmount': function () {
      this.fetchReports()
    },
    searchKeyword(keyword) {
      if (keyword.length <= 0 || keyword.length >= 3) {
        if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(
          () => this.fetchReports(keyword),
          300,
        )
      }
    },
    status() {
      this.fetchReports()
    },
  },
  mounted() {
    this.$nextTick(() => {
      const lastSelectedPage = this.$store.state.app.operatorLkpsPage
      if (this.pagination.currentPage == lastSelectedPage) {
        this.fetchReports()
      } else {
        this.fetchReports('', lastSelectedPage)
      }
    })
  },
  methods: {
    whoIsMe() {
      useJwt.http
        .get('whoisme', {})
        .then(response => {
          let { userData } = response.data
          const role = userData.roles[0]
          const ability = role.abilities[0]
          const { action, subject } = ability
          this.$ability.update([{ action, subject }])

          // Change user's ability to ability (singular) instead of abilities (plural)
          userData = { ...userData, ability: { action, subject } }
          delete userData.abilities

          // Change user's role to role (singular) instead of roles (plural)
          userData = { ...userData, role: role.name }
          delete userData.roles

          useJwt.setToken(response.data.accessToken)
          useJwt.setRefreshToken(response.data.refreshToken)
          localStorage.setItem('userData', JSON.stringify(userData))
          return userData.lkps_profile.ts
        })
        .catch(() => this.$alert({
          title: 'Anda belum mengisi profile.',
          variant: 'danger',
          icon: 'XIcon',
        }))
      // eslint-disable-next-line no-return-assign
        .finally(() => (this.fetchLoading = false))
    },
    fetchReports(keyword = '', page = this.pagination.currentPage) {
      this.fetchLoading = true

      useJwt.http
        .get('lkps_table', {
          params: {
            page: page - 1,
            size: this.pagination.selectedAmount,
            sort: 'ASC',
            ts: this.ts.replace(/ /g, ''),
            state: `state_${this.shortName.toLowerCase()}`,
            short_name: this.shortName,
            program_studi: this.programStudi,
            number_and_title: keyword,
            status: this.status,
            is_operator: true, // default from operator single data
          },
        })
        .then(response => {
          let number = page * this.pagination.selectedAmount
              - this.pagination.selectedAmount
              + 1
          this.reports = response.data.lkps_tables.map(list => {
            list.number = number++
            list.number_and_title = list.list_lkps_table.number_and_title
            list.action = ''
            return list
          })

          this.pagination.totalItems = response.data.total_items
          this.completed = response.data.completed
        })
        .catch(() => this.$alert({
          title: 'Telah terjadi kesalahan.',
          variant: 'danger',
          icon: 'XIcon',
        }))
        .finally(() => {
          this.fetchLoading = false
          this.$nextTick(() => (this.pagination.currentPage = page))
        })
    },
    // testing
    exportAllData() {
      this.spinnerDownload = !this.spinnerDownload

      useJwt.http
        .get('lkps/export/excel', {
          params: {
            ts: this.ts,
            program_studi: this.programStudi,
            short_name: this.shortName,
          },
        })
        .then(response => {
          this.$alert({
            title: response.data.message,
            variant: 'success',
            icon: 'CheckIcon',
          })
          window.open(`${this.BACKEND_URL}/${response.data.link_file_excel}`)
          this.spinnerDownload = !this.spinnerDownload
        })
        .catch(() => this.$alert({
          title: 'Telah terjadi kesalahan.',
          variant: 'danger',
          icon: 'XIcon',
        }))
    },
    showTour() {
      this.$refs.tour.showTour()
    },
  },
}
</script>
<style>
  table#table-transition-gjmf .flip-list-move {
    transition: transform 0.4s;
  }
</style>
